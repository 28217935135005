import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ContentPreview from "../components/ContentPreview"

const useStyles = makeStyles(theme => ({
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  postTemplate: {
    maxWidth: "761px",
    margin: "0 auto 4rem",
  },
  title: {
    fontSize: "6rem",
    display: "block",
    textAlign: "center",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  info: {
    fontSize: "1.4rem",
    textAlign: "right",
  },
  article: {
    marginTop: "4rem",
    textAlign: "right",
  },
  h1: {
    fontSize: "4rem",
    textAlign: "right",
    display: "block",
    marginTop: "1.8rem",
    marginBottom: "1.8rem",
    marginLeft: 0,
    marginRight: 0,
    fontWeight: "bold",
  },
  h2: {
    fontSize: "3.2rem",
    textAlign: "right",
    display: "block",
    marginTop: "1.6rem",
    marginBottom: "1.6rem",
    marginLeft: 0,
    marginRight: 0,
    fontWeight: "bold",
  },
  h3: {
    fontSize: "2.8rem",
    textAlign: "right",
    display: "block",
    marginTop: "1.4rem",
    marginBottom: "1.4rem",
    marginLeft: 0,
    marginRight: 0,
    fontWeight: "bold",
  },
  h4: {
    fontSize: "2.4rem",
    textAlign: "right",
    display: "block",
    marginTop: "1.2rem",
    marginBottom: "1.2rem",
    marginLeft: 0,
    marginRight: 0,
    fontWeight: "bold",
  },
  h5: {
    fontSize: "2rem",
    textAlign: "right",
    display: "block",
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: 0,
    marginRight: 0,
    fontWeight: "bold",
  },
  h6: {
    fontSize: "1.6rem",
    textAlign: "right",
    display: "block",
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: 0,
    marginRight: 0,
    fontWeight: "bold",
  },
  p: {
    margin: "2rem 0",
    textAlign: "right",
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
  },
  ul: {
    listStyle: "none",
    textAlign: "right",
    // listStylePosition: "inside",
    margin: "1rem 0",
    "& li p::before": {
      content: `"\\2bc0"`,
      color: "#c30",
      width: "1rem",
      marginLeft: ".8rem",
    },
  },
  ol: {
    textAlign: "right",
    listStyle: "arabic-indic",
    margin: "1rem 0",
  },
  li: {
    textAlign: "right",
    fontSize: "1.6rem",
  },
  image: {
    width: "100%",
    margin: "2rem 0",
  },
  quoteContainer: {
    borderRight: "solid 8px #c30",
    fontStyle: "italic",
    margin: "2rem 0",
    paddingRight: "1em",
    paddingLeft: "2em",
    textAlign: "right",
    fontSize: "1.6rem",
  },
  citation: {
    paddingRight: "1.6rem",
    textAlign: "left",
  },

  //
  button: {
    fontSize: "2rem",
    textAlign: "right",
    margin: "4rem auto 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
  sectionSeperator: {
    marginBottom: "8rem",
  },
  centeredTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
  },
  link: {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: "#c30",
    fontWeight: "bold",
  },
}))

const Post = ({ data }) => {
  const classes = useStyles()

  const { author, date, title, content, image, summary } = data.post
  const postImage = getImage(image)

  // Calculate estimated reading time
  const estimatedReadingTime = article => {
    const wordsPerMinute = 200 // Average case.
    let result

    let textLength = article.split(" ").length
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute)
      result =
        value === 1
          ? `وقت القراءة دقيقة واحدة`
          : value === 2
          ? `وقت القراءة دقيقتان`
          : value === 3
          ? `وقت القراءة ${value} دقائق`
          : value < 10
          ? `وقت القراءة ${value} دقائق`
          : `وقت القراءة ${value} دقيقة`
      return result
    }
  }
  const readingTime = estimatedReadingTime(content.raw)

  // Represent the date of the post in Arabic
  const dateToArabicRepresentation = date
    .split(" ")
    .map(part => {
      switch (part) {
        case "seconds":
          return "ثواني"
        case "minutes":
          return "دقائق"
        case "hour":
          return "ساعة"
        case "hours":
          return "ساعات"
        case "day":
          return "يوم"
        case "days":
          return "أيام"
        case "month":
          return "شهر"
        case "months":
          return "شهور"
        case "year":
          return "سنة"
        case "years":
          return "سنوات"
        case "ago":
          return "منذ"
        case "a":
          return ""
        case "an":
          return ""

        default:
          return part
      }
    })
    .reverse()
    .join(" ")

  // Configue how to display the post sourced from contentful
  const options = {
    renderMark: {
      [MARKS.BOLD]: node => {
        return <strong>{node}</strong>
      },
      [MARKS.UNDERLINE]: node => {
        return <u>{node}</u>
      },
      [MARKS.ITALIC]: node => {
        return <em>{node}</em>
      },
    },
    renderNode: {
      [INLINES.HYPERLINK]: (link, children) => {
        return (
          <a
            href={link.data.uri}
            className={classes.link}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <GatsbyImage
            image={getImage(node.data.target)}
            alt={node.data.target.title}
          />
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 className={classes.h1}>{children}</h1>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className={classes.h2}>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 className={classes.h3}>{children}</h3>
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <h4 className={classes.h4}>{children}</h4>
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <h5 className={classes.h5}>{children}</h5>
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        return <h6 className={classes.h6}>{children}</h6>
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <ol className={classes.ol}>{children}</ol>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className={classes.li}>{children}</li>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className={classes.ul}>{children}</ul>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className={classes.p}>{children}</p>
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return (
          <blockquote className={classes.quoteContainer}>{children}</blockquote>
        )
      },
      [BLOCKS.HR]: node => {
        return <hr />
      },
    },
  }

  const postContent = renderRichText(content, options)

  const pageMeta = {
    title: title,
    description: summary.summary,
    keywords: title.split(" "),
  }

  return (
    <Layout pageMeta={pageMeta} background={postImage}>
      <section className={classes.postTemplate}>
        <Grid container direction="column">
          {/* Title */}
          <Grid item align="center">
            <Typography className={classes.title}>{title}</Typography>
          </Grid>

          {/* info */}
          <Grid item container justify="center" spacing={4}>
            <Grid item>
              <Typography className={classes.info}>| {author}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                | {dateToArabicRepresentation}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>| {readingTime}</Typography>
            </Grid>
          </Grid>

          {/* Content */}
          <Grid item className={classes.article}>
            <article className={classes.postContent}>{postContent}</article>
          </Grid>
        </Grid>
      </section>

      <Grid item className={classes.sectionSeperatorShadowed} />

      {/* Related posts */}
      <Grid item container direction="column">
        <Grid item container direction="column">
          {data.posts.nodes && (
            <>
              <Grid item>
                <Typography className={classes.centeredTitle}>
                  منشورات ذات صلة
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <ContentPreview
                    data={data}
                    content="blogPosts"
                    direction="column"
                    isButton
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item container justify="center">
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/blog"
              className={classes.button}
            >
              الرجوع إلى المدونة
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.sectionSeperator} />
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query GetPost($slug: String, $tags: [String]) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      author
      date(fromNow: true)
      title
      summary {
        summary
      }
      image {
        gatsbyImageData
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData
          }
        }
      }
    }
    posts: allContentfulBlogPost(
      filter: { tags: { in: $tags }, slug: { ne: $slug } }
    ) {
      nodes {
        title
        slug
        summary {
          summary
        }
        image {
          gatsbyImageData
        }
      }
    }
  }
`

export default Post
